import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { Wrapper, Arrow, InterestTile, SectionWrapper, SportTile, StyledCheckBox, Button, ButtonContainer, TileTitle, CheckMark2, TilesDiv } from './styles';
import { FormContainer, HeaderBox, Title } from '../RegistrationForm/styles';

import Waiting from '../Waiting';
import { WaterSports, BallSports, WheelSports, WinterSports, CombatSports, ResistanceTraining } from '../SvgComponents';
import FormWizardSteps from '../FormWizardSteps';

import { getAreaOfInterests, addAreaOfInterests, getAllWellnessInterests } from '../../redux/actions';

import { ImageUrl } from '../../utils/constants';

import { wellnessSportsFirstColumn, wellnessSportsSecondColumn } from '../../../mockData';
import { withTranslation } from 'react-i18next';
import LazyImage from '../common/LazyImage/LazyImage';

const Components = {
  WaterSports: WaterSports,
  BallSports: BallSports,
  WinterSports: WinterSports,
  WheelSports: WheelSports,
  CombatSports: CombatSports,
  ResistanceTraining: ResistanceTraining
};

class RegistrationWellnessInterests extends Component {

  constructor() {
    super();
    this.state = {

      openedSectionArray: [""]
    };
  }

  componentDidMount() {
    const { getAreaOfInterests, areaOfInterest, fetchWellnessInterests, wellnessInterests, companyId, history } = this.props;
    if (companyId < 0) {
      history.push('/user');
    }
    if (!areaOfInterest) getAreaOfInterests();
    if (!wellnessInterests) fetchWellnessInterests();
  }


  showRelatedSportsV2 = (sportTitle) => {
    const { openedSectionArray } = this.state;
    if (openedSectionArray.includes(sportTitle)) {
      let newData = openedSectionArray.filter((obj) => obj !== sportTitle)
      this.setState({ openedSectionArray: newData })
    } else {
      let prevData = openedSectionArray
      prevData.push(sportTitle)
      this.setState({ openedSectionArray: prevData })
    }
  };
  getComponentName = (title) => {
    if (title === 'other') {
      return '';
    } else {
      let UpdatedTitle = title.replace(
        /\w\S*/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
      return Components[UpdatedTitle.replace(/ /g, '')];
    }
  };

  onChange = (sportId) => {
    const { addAreaOfInterests } = this.props;
    addAreaOfInterests(sportId);
  };

  renderWellnessInterests = (sports) => sports.map((sport) => {
    const { wellnessInterests, areaOfInterest } = this.props;
    let array = [];
    wellnessInterests.map((interest) => {
      if (interest.interest_type.toLowerCase().trim() === sport.toLowerCase().trim()) {
        array.push(interest);
      }
    });
    const ComponentName = this.getComponentName(sport);
    return (
      <div key={sport}>
        <InterestTile onClick={() => this.showRelatedSportsV2(sport)} isIndividualSport={sport === 'individual sports'}>
          {
            sport === 'other' ? <img src="/public/images/other-sports.png" /> : (
              sport === 'individual sports' ? <LazyImage src={ImageUrl + "/images/aoi_icons/individualSports.png"} /> : <ComponentName color="#003333" />)
          }
          <TileTitle smallLine={sport === 'other'}>{sport}</TileTitle>
          <Arrow active={this.state.openedSectionArray.includes(sport)} isOtherSports={sport === 'other'} />
        </InterestTile>

        {
          (this.state.openedSectionArray.includes(sport)) &&
          <TilesDiv>

            {array.map((data) => (
              <SportTile key={data.name}
                onClick={() => this.onChange(data.id)}

              >
                <div className='right'>
                  <div className='imgDivSport'> <img src={`${ImageUrl}/${data.interest_icon}`} /></div>
                  <span>{data.name}</span>
                </div>
                <StyledCheckBox>

                  <input
                    type="checkbox"
                    checked={areaOfInterest.includes(data.id)}
                    onChange={() => this.onChange(data.id)}
                  />
                  <CheckMark2 checked={areaOfInterest.includes(data.id)} />
                </StyledCheckBox>
              </SportTile>
            ))}
          </TilesDiv>}
      </div>
    )
  });

  saveAreaOfInterests = () => {
    const { history } = this.props;
    history.push('/user/step3');
  };

  render() {
    const { history, wellnessInterests, wellnessError, areaOfInterest, t } = this.props;
    return (
      <Wrapper>
        <FormWizardSteps history={history} activeStep={2} />
        <Title>{t("Wellness Interests")}</Title>

        <FormContainer >
          <HeaderBox>
            <h1>{t("Wellness Interest")}</h1>
            <h3>{t("At least One Selection is Required. Select All That Apply.")}</h3>
          </HeaderBox>
          {
            (!wellnessInterests && !wellnessError) ? <Waiting /> :
              <div>
                <SectionWrapper>
                  {this.renderWellnessInterests(wellnessSportsFirstColumn)}

                  {this.renderWellnessInterests(wellnessSportsSecondColumn)}
                </SectionWrapper>
              </div>
          }
          <ButtonContainer>
            <Button color={'rgba(105, 194, 255, 0.1)'} onClick={() => history.push('/user/step1')}>
              <i className="fas fa-chevron-left" />
              {t("Go Back")}
            </Button>
            <Button onClick={this.saveAreaOfInterests} disabled={areaOfInterest.length < 1}>
              {t("Next")}
              <i className="fas fa-chevron-right" />
            </Button>
          </ButtonContainer>
        </FormContainer>


      </Wrapper>
    )
  }
}

RegistrationWellnessInterests.propTypes = {
  history: PropTypes.object.isRequired,
  areaOfInterest: PropTypes.array.isRequired,
  addAreaOfInterests: PropTypes.func.isRequired,
  getAreaOfInterests: PropTypes.func.isRequired,
  fetchWellnessInterests: PropTypes.func.isRequired,
  wellnessInterests: PropTypes.array,
  wellnessError: PropTypes.string,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func

};

const mapStateToProps = (state) => ({
  areaOfInterest: state.register.aoi,
  wellnessInterests: state.register.wellnessInterests,
  wellnessError: state.register.wellnessError
});

const mapDispatchToProps = (dispatch) => ({
  fetchWellnessInterests: () => dispatch(getAllWellnessInterests()),
  getAreaOfInterests: () => dispatch(getAreaOfInterests()),
  addAreaOfInterests: (areaOfInterest) => dispatch(addAreaOfInterests(areaOfInterest))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegistrationWellnessInterests));